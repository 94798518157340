import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SharedDialogComponent } from '../../../shared/components/shared-dialog/shared-dialog.component';
import { SharedDialogType } from '../../../shared/components/shared-dialog/models/shared-dialog-type';
import { ApplicationFieldXlatItem } from '../../../core/models/application-field/application-field-xlat-item.model';
import { ApplicationFieldsService } from '../../../core/services/application-fields/application-fields.service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import differenceWith from 'lodash-es/differenceWith';
import isEqual from 'lodash-es/isEqual';
import cloneDeep from 'lodash-es/cloneDeep';
import differenceBy from 'lodash-es/differenceBy';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSidenav } from '@angular/material/sidenav';
import { AppConstants } from '../../../core/app-constants';
import { ApplicationFieldItemXlatItemsFacade } from '../state/application-field-item-xlat-items/application-field-item-xlat-items.facade';
import { ApplicationFieldXlat } from '../../../core/models/application-field/application-field-xlat.model';
import { ApplicationFieldItemXlatsFacade } from '../state/application-field-item-xlats/application-field-item-xlats.facade';
import { DiscardDataService } from '../../../core/services/discard-data/discard-data.service';
import * as XLSX from 'xlsx';
import { SnackBarDataService } from '../../../core/services/snack-bar-data/snack-bar-data.service';
import * as Papa from 'papaparse';

export type PartialXlat = Pick<ApplicationFieldXlat, 'id' | 'name' | 'tenantId'>;

@Component({
    selector: 'app-application-field-xlat-items',
    templateUrl: './application-field-xlat-items.component.html',
    styleUrls: ['./application-field-xlat-items.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationFieldXlatItemsComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject();

    public originalApplicationFieldXlatItems = new Array<ApplicationFieldXlatItem>();
    public selection = new SelectionModel<ApplicationFieldXlatItem>(true);
    public inputPlaceholder = AppConstants.INPUT_PLACEHOLDER;
    public maxLength = 128;
    public loading$: Observable<boolean>;
    public loaded$: Observable<boolean>;
    public xlatLoading$ = new BehaviorSubject<boolean>(true);
    public originApplicationFieldItemXlat: ApplicationFieldXlat;
    public applicationFieldPartialXlat: PartialXlat;
    public acceptedFormats =
        'text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';


    @Input() public readonlySection: boolean;
    @Input() public selectedApplicationFieldXlat$: BehaviorSubject<ApplicationFieldXlat> =
        new BehaviorSubject<ApplicationFieldXlat>(null);
    @Input() public sourceScreenName$: BehaviorSubject<string>;
    @Input() public sidenav: MatSidenav;
    @Input() public selectedApplicationFieldXlatId$: BehaviorSubject<number>
        = new BehaviorSubject<number>(null);

    @Output() public deleteAllClick = new EventEmitter();
    @Output() public itemsChanged = new EventEmitter<boolean>();
    @Output() public closeSidenavClick = new EventEmitter();

    @Output() public xlatNameChanged = new EventEmitter<PartialXlat>();

    @ViewChild('table') table;

    public dataSource = new MatTableDataSource<ApplicationFieldXlatItem>();
    public displayedColumns: string[] = ['selected', 'valueSource', 'valueTarget'];

    constructor(
        private applicationFieldsService: ApplicationFieldsService,
        private dialog: MatDialog,
        private changeDetector: ChangeDetectorRef,
        private applicationFieldItemXlatItemsFacade: ApplicationFieldItemXlatItemsFacade,
        private applicationFieldItemXlatsFacade: ApplicationFieldItemXlatsFacade,
        private snackBarDataService: SnackBarDataService,
        private discardDataService: DiscardDataService
    ) { }

    ngOnInit(): void {
        this.loading$ = this.applicationFieldItemXlatItemsFacade.loading$;
        this.loaded$ = this.applicationFieldItemXlatItemsFacade.loaded$;
        this.getApplicationFieldXlatItems();
        this.sidenav.closedStart
            .pipe(
                tap(() => {
                    this.resetValues();
                }, takeUntil(this.destroy$))
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public getApplicationFieldXlatItems(): void {
        this.selectedApplicationFieldXlatId$
            .pipe(
                withLatestFrom(this.selectedApplicationFieldXlat$),
                tap(([value, parentApplicationFieldXlat]) => {
                    this.applicationFieldsService.getApplicationFieldXlatItems(value).pipe(
                        tap((applicationFieldXlatItems) => {
                            if (!!applicationFieldXlatItems?.length) {
                                this.applicationFieldPartialXlat = {
                                    name: applicationFieldXlatItems[0].applicationFieldXlat.name,
                                    id: applicationFieldXlatItems[0].applicationFieldXlat.id,
                                    tenantId: applicationFieldXlatItems[0].applicationFieldXlat.tenantId
                                };
                            }
                            else {
                                this.applicationFieldPartialXlat = {
                                    name: parentApplicationFieldXlat.name,
                                    id: parentApplicationFieldXlat.id,
                                    tenantId: parentApplicationFieldXlat.tenantId
                                };
                            }
                            this.originApplicationFieldItemXlat = cloneDeep(this.applicationFieldPartialXlat);
                            this.selectedApplicationFieldXlat$.next(this.applicationFieldPartialXlat);
                            this.dataSource.data = cloneDeep(applicationFieldXlatItems);
                            this.originalApplicationFieldXlatItems = cloneDeep(applicationFieldXlatItems);
                            this.changeDetector.detectChanges();
                        }),
                        tap(() => this.xlatLoading$.next(false)),
                        takeUntil(this.destroy$)
                    ).subscribe();
                })
            )
            .subscribe();
    }

    public saveApplicationFieldXlatItems(): void {
        const itemsToDelete = differenceBy(
            this.originalApplicationFieldXlatItems,
            this.dataSource.data.filter((item) => item.id),
            'id'
        );
        this.deleteApplicationFieldXlatItems(itemsToDelete);

        if (
            this.dataSource.data.some(
                (item) =>
                    !item.valueSource ||
                    !item.valueTarget ||
                    item.valueTarget.length > this.maxLength ||
                    item.valueSource.length > this.maxLength
            )
        ) {
            return;
        }

        const itemsToUpsert = differenceWith(this.dataSource.data, this.originalApplicationFieldXlatItems, isEqual);
        this.upsertApplicationFieldXlatItems(itemsToUpsert);
        this.sidenav.close();
    }

    public upsertApplicationFieldXlatItems(itemsToUpsert: Array<ApplicationFieldXlatItem>): void {
        if (this.originApplicationFieldItemXlat.name !== this.applicationFieldPartialXlat.name) {
            this.applicationFieldItemXlatsFacade.updateApplicationFieldItemXlat(
                cloneDeep({ ...this.selectedApplicationFieldXlat$.value, name: this.applicationFieldPartialXlat.name })
            );
            this.xlatNameChanged.emit(this.applicationFieldPartialXlat);
        }
        this.applicationFieldItemXlatItemsFacade.upsertApplicationFieldItemXlatItems(itemsToUpsert);
    }

    public deleteApplicationFieldXlatItems(itemsToDelete: Array<ApplicationFieldXlatItem>): void {
        itemsToDelete.map((item) => this.applicationFieldItemXlatItemsFacade.deleteApplicationFieldItemXlatItems(item.id));
    }

    public deleteAllXlatRows(): void {
        const firstDialog = this.dialog.open(SharedDialogComponent, {
            width: '33%',
            data: {
                title: 'Are you sure you want to delete all?',
                confirmButtonText: 'ACCEPT',
                sharedDialogType: SharedDialogType.Confirmation
            }
        });

        firstDialog
            .afterClosed()
            .pipe(
                filter((firstResult) => firstResult),
                switchMap(() => {
                    const secondDialog = this.dialog.open(SharedDialogComponent, {
                        width: '33%',
                        data: {
                            title: 'Press delete to confirm',
                            confirmButtonText: 'DELETE',
                            sharedDialogType: SharedDialogType.Confirmation
                        }
                    });

                    return secondDialog.afterClosed().pipe(
                        filter((secondResult) => secondResult),
                        tap(() => {
                            this.deleteAllClick.emit();
                            this.sidenav.close();
                        })
                    );
                })
            )
            .subscribe();
    }

    public deleteXlatRows(): void {
        this.dataSource.data = this.dataSource.data.filter((x) => !this.selection.isSelected(x));
        this.selection.clear();
        this.itemsChanged.emit(this.itemsHasChanged());
    }

    public addApplicationFieldXlatItem(): void {
        const newRow = {
            valueSource: '',
            valueTarget: '',
            notation: '',
            tenantId: AppConstants.DEFAULT_TENANT_ID,
            applicationFieldXlat: this.selectedApplicationFieldXlat$.value
        } as ApplicationFieldXlatItem;

        this.dataSource.data.push(newRow);
        this.dataSource._updateChangeSubscription();
        this.itemsChanged.emit(this.itemsHasChanged());
        this.focusLastRow();
    }

    public importXlatFromExcelFile(target: HTMLInputElement): void {
        const file = target.files[0];
        const dialogRef = this.dialog.open(SharedDialogComponent, {
            width: '33%',
            data: {
                title: 'All current values will be replaced with the CSV file',
                confirmButtonText: 'CONTINUE',
                sharedDialogType: SharedDialogType.Confirmation
            }
        });

        if (!this.acceptedFormats.includes(file.type)) {
            this.snackBarDataService.showSnackBar('highlight_off', 'Invalid File Type', AppConstants.ERROR_COLOR);
            return;
        }

        if (file.type === 'text/csv') {
            this.readCSVFile(file, dialogRef);
        } else {
            this.readExcelFile(file, dialogRef);
        }
    }

    private readCSVFile(file: File, dialogRef: MatDialogRef<SharedDialogComponent>): void {
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            transformHeader: (header) => header.trim(),
            complete: (results) => {
                dialogRef
                    .afterClosed()
                    .pipe(
                        tap((dialogResult) => {
                            if (dialogResult) {
                                const xlat = results.data;
                                this.updateXlatData(xlat[0][AppConstants.EXPORT_TO_EXCEL.XLAT_TO_EXCEL.XLAT_EXCEL_COLUMN], xlat.slice(1));
                            }
                        })
                    )
                    .subscribe();
            }
        });
    }

    private readExcelFile(file: File, dialogRef: MatDialogRef<SharedDialogComponent>): void {
        this.readExcelFileAsArrayBuffer(file)
            .pipe(
                switchMap((bufferArray) =>
                    dialogRef.afterClosed().pipe(
                        tap((result) => {
                            if (result) {
                                const workbook = XLSX.read(bufferArray, { type: 'buffer' });
                                const worksheet = workbook.Sheets[Object.keys(workbook.Sheets)[0]];
                                const xlat = XLSX.utils
                                    .sheet_to_json(worksheet)
                                    .map((item) =>
                                        Object.fromEntries(Object.entries(item).map(([key, value]) => [key.trim(), value]))
                                    );
                                this.updateXlatData(xlat[0][AppConstants.EXPORT_TO_EXCEL.XLAT_TO_EXCEL.XLAT_EXCEL_COLUMN], xlat.slice(1));
                            }
                        })
                    )
                )
            )
            .subscribe();
    }

    private readExcelFileAsArrayBuffer(file: File): Observable<ArrayBuffer> {
        return new Observable<ArrayBuffer>((subscriber) => {
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                subscriber.next(e.target.result as ArrayBuffer);
                subscriber.complete();
            };
            fileReader.readAsArrayBuffer(file);
        });
    }

    private updateXlatData(xlatName: string, xlatData: any[]): void {
        this.applicationFieldPartialXlat.name = xlatName;
        this.dataSource.data = xlatData.map(
            (item) =>
            ({
                valueSource: item[AppConstants.EXPORT_TO_EXCEL.XLAT_TO_EXCEL.SOURCE_VALUE_EXCEL_COLUMN],
                valueTarget: item[AppConstants.EXPORT_TO_EXCEL.XLAT_TO_EXCEL.TARGET_VALUE_EXCEL_COLUMN],
                notation: '',
                tenantId: AppConstants.DEFAULT_TENANT_ID,
                applicationFieldXlat: this.selectedApplicationFieldXlat$.value
            } as ApplicationFieldXlatItem)
        );
        this.changeDetector.detectChanges();
    }

    public exportXlatToExcelFile(): void {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet([
            [
                AppConstants.EXPORT_TO_EXCEL.XLAT_TO_EXCEL.XLAT_EXCEL_COLUMN,
                AppConstants.EXPORT_TO_EXCEL.XLAT_TO_EXCEL.SOURCE_VALUE_EXCEL_COLUMN,
                AppConstants.EXPORT_TO_EXCEL.XLAT_TO_EXCEL.TARGET_VALUE_EXCEL_COLUMN
            ],
            [this.selectedApplicationFieldXlat$.value.name],
            ...this.dataSource.data.map((xlatItem) => {
                return ['', xlatItem.valueSource, xlatItem.valueTarget];
            })
        ]);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'XLAT');
        XLSX.writeFile(workbook, 'XLAT.xlsx');
    }

    public itemsHasChanged(): boolean {
        return (
            differenceWith(this.dataSource.data, this.originalApplicationFieldXlatItems, isEqual).length ||
            this.dataSource.data.length !== this.originalApplicationFieldXlatItems.length ||
            this.originApplicationFieldItemXlat?.name !== this.applicationFieldPartialXlat.name
        );
    }

    private focusLastRow(): void {
        this.table?._elementRef.nativeElement
            .getElementsByTagName('tr')
        [this.dataSource.data.length].getElementsByTagName('input')[1]
            .focus();
    }

    private resetValues(): void {
        this.dataSource.data = [];
        this.originalApplicationFieldXlatItems = [];
        this.selection.clear();
    }

    public closeSidenav(): void {
        this.itemsHasChanged() ? this.showDiscardDialog() : this.closeSidenavClick.emit();
    }

    private showDiscardDialog(): void {
        const dialogRef = this.discardDataService.openConfirmationDialog();

        dialogRef
            .afterClosed()
            .pipe(
                tap((res) => {
                    if (res) {
                        this.closeSidenavClick.emit();
                    }
                })
            )
            .subscribe();
    }
}

