import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TeamMember } from '../../models/team-member/team-member.model';

@Injectable({
    providedIn: 'root'
})
export class TeamMembersService {
    uri = environment.apiUrl + '/teamMembers';

    constructor(private readonly http: HttpClient) {}

    public getTeamMembers(): Observable<Array<TeamMember>> {
        return this.http.get<Array<TeamMember>>(this.uri);
    }

    public getRPPTeamMembers(): Observable<Array<TeamMember>> {
        return this.http.get<Array<TeamMember>>(`${this.uri}/rppOnly`);
    }

    public getTeamMemberById(id: number): Observable<TeamMember> {
        return this.http.get<TeamMember>(`${this.uri}/${id}`);
    }

    public createTeamMembers(items: Array<TeamMember>): Observable<Array<TeamMember>> {
        return this.http.put<Array<TeamMember>>(this.uri, { items });
    }

    public updateTeamMembers(items: Array<TeamMember>): Observable<Array<TeamMember>> {
        return this.http.patch<Array<TeamMember>>(this.uri, { items });
    }

    public deleteTeamMember(id: number): Observable<void> {
        return this.http.delete<void>(`${this.uri}/${id}`);
    }
}
