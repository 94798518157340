import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTeamMembers from './team-members.reducer';
import { teamMembersFeatureKey, TeamMembersState } from './team-members.reducer';
import { AppConstants } from '../../app-constants';
import { Status } from '../../enums/status';

const selectTeamMembersState = createFeatureSelector<TeamMembersState>(teamMembersFeatureKey);

export const selectTeamMembers = createSelector(selectTeamMembersState, fromTeamMembers.selectAll);

export const selectIntegrationManagers = createSelector(selectTeamMembers, (teamMembers) =>
    teamMembers.filter(
        (teamMember) =>
            teamMember.teamRole.some((role) => role === AppConstants.INTEGRATION_MANAGER_ROLE) &&
            teamMember.status === Status.Active
    )
);

export const selectRppIMs = createSelector(selectTeamMembers, (teamMembers) =>
    teamMembers.filter(teamMember =>
        teamMember.teamRole.some((role) => role === AppConstants.RPP_INTEGRATION_MANAGER_ROLE) &&
        teamMember.status === Status.Active)
);

export const selectRppMechanics = createSelector(selectTeamMembers, (teamMembers) =>
    teamMembers.filter(teamMember =>
        teamMember.teamRole.some((role) => role === AppConstants.RPP_MECHANIC_ROLE) &&
        teamMember.status === Status.Active)
);

export const selectPartners = createSelector(selectTeamMembers, (teamMembers) =>
    teamMembers.filter(
        (teamMember) =>
            teamMember.teamRole.some((role) => role === AppConstants.PARTNER_ROLE) &&
            teamMember.status === Status.Active
    )
);

export const selectMechanics = createSelector(selectTeamMembers, (teamMembers) =>
    teamMembers.filter(
        (teamMember) =>
            teamMember.teamRole.some((role) => role === AppConstants.MECHANIC_ROLE) && teamMember.status === Status.Active
    )
);

export const selectSalespersons = createSelector(selectTeamMembers, (teamMembers) =>
    teamMembers.filter(
        (teamMember) =>
            teamMember.teamRole.some((role) => role === AppConstants.SALES_PERSON_ROLE) && teamMember.status === Status.Active
    )
);

export const selectTeamMemberById = (id: number) =>
    createSelector(selectTeamMembersState, (state) => {
        if (state.loadedEntitiesById.some((item) => item === id)) {
            return state.entities[id];
        }

        return null;
    });

export const selectLoadedTeamMembersById = createSelector(selectTeamMembersState, (state) => state.loadedEntitiesById);

export const selectLoading = createSelector(selectTeamMembersState, (state) => state.loading);

export const selectLoadingById = createSelector(selectTeamMembersState, (state) => state.loadingById);

export const selectLoaded = createSelector(selectTeamMembersState, (state) => state.loaded);
