export enum AdminControlCode {
    ProjectPhase = 'PROJECT-PHASE',
    DataMapFieldUsage = 'DATA-MAP-FIELD-USAGE',
    // renamed from FieldUsage to Action
    Action = 'ACTION',
    Used = 'USED',
    ReviewPhase = 'REVIEW-PHASE',
    RecordType = 'RECORD-TYPE',
    TeamRole = 'TEAM-ROLE',
    FormTicket = 'FORM-TICKET',
    FormNotify = 'FORM-NOTIFY',
    NewOI = 'NEW-OI',
    TimeZone = 'TIME-ZONE',
    Sections = "SECTIONS",
    Workflow = 'WORKFLOW',
    ChangeLog = 'CHANGE-LOG-PURGE',
    Platforms = "PLATFORMS",
    RPPTypes = "RPP-TYPES",
    OrderCreatedBy = "ORDER-CREATED-BY",
    PackagesManagement ="PACKAGES-MANAGEMENT",
    ProfessionalServiceTypes ="PPS-TYPE"
}
