<nav class="menu" *ngLet="user$ | async as user">
    <div [ngClass]="{ menu__logo: true, 'menu__logo--shrank': !expandMainSidenav }">
        <img src="assets/img/logo.svg" alt="Joynd" routerLink="/" />
    </div>

    <a class="menu__item" routerLink="/integration-search" routerLinkActive="menu__item--active">
        <mat-icon class="menu__item-img" [ngClass]="{'menu__item-img--shrank': !expandMainSidenav}">search</mat-icon>
        <div *ngIf="expandMainSidenav" class="menu__item-text">Search Integrations</div>
    </a>

    <a *ngIf="showMenuItem(user, permissions.PartnerIntegrationClientView)" class="menu__item"
        routerLink="/create-partner-integration" routerLinkActive="menu__item--active">
        <mat-icon class="menu__item-img"
            [ngClass]="{'menu__item-img--shrank': !expandMainSidenav}">add_circle_outline</mat-icon>
        <div *ngIf="expandMainSidenav" class="menu__item-text">Add Integration</div>
    </a>

    <a *ngIf="showMenuItem(user, permissions.PartnerIntegrationClientView) && !hasCloudMillsUserRole(user)"
        class="menu__item" routerLink="/partner-users" routerLinkActive="menu__item--active">
        <mat-icon class="menu__item-img"
            [ngClass]="{ 'menu__item-img--shrank': !expandMainSidenav}">person_outline</mat-icon>
        <div *ngIf="expandMainSidenav" class="menu__item-text">Users</div>
    </a>

    <a *ngIf="showMenuItem(user, permissions.PartnerIntegrationClientView) || showMenuItem(user, permissions.PartnerIntegrationUserView)"
        class="menu__item" routerLink="/partner-dashboard" [queryParams]="{ integrationId: user.selectedIntegrationId }"
        routerLinkActive="menu__item--active">
        <mat-icon class="menu__item-img"
            [ngClass]="{ 'menu__item-img--shrank': !expandMainSidenav}">bar_chart</mat-icon>
        <div *ngIf="expandMainSidenav" class="menu__item-text">Integration Details</div>
    </a>

    <div *ngFor="let page of pages$ | async">
        <a *ngIf="showMenuItem(user, permissions.PartnerEngagementPagesView + '.' + page.id)"
            routerLinkActive="menu__item--active" [routerLink]="'/partner-page/' + page.id"
            class="menu__item cursor-pointer">
            <mat-icon class="menu__item-img" [ngClass]="{ 'menu__item-img--shrank': !expandMainSidenav}">web</mat-icon>
            <div *ngIf="expandMainSidenav" class="menu__item-text">{{ page.name }}</div>
        </a>
    </div>

    <a *ngIf="showMenuItem(user, permissions.PortalEventNotificationsView)" class="menu__item" routerLink="/event-notifications"
        routerLinkActive="menu__item--active">
        <mat-icon class="menu__item-img"
            [ngClass]="{ 'menu__item-img--shrank': !expandMainSidenav}">event</mat-icon>
        <div *ngIf="expandMainSidenav" class="menu__item-text">Integration Events</div>
    </a>

    <a *ngIf="showMenuItem(user, permissions.ProfessionalServicesView)" class="menu__item" routerLink="/professional-services"
        routerLinkActive="menu__item--active">
        <mat-icon class="menu__item-img"
            [ngClass]="{ 'menu__item-img--shrank': !expandMainSidenav}">linked_services</mat-icon>
        <div *ngIf="expandMainSidenav" class="menu__item-text">Professional Services</div>
    </a>

    <a *ngIf="showMenuItem(user, permissions.PartnerIntegrationClientView)" class="menu__item" routerLink="/change-logs"
        routerLinkActive="menu__item--active">
        <mat-icon class="menu__item-img"
            [ngClass]="{ 'menu__item-img--shrank': !expandMainSidenav}">change_history</mat-icon>
        <div *ngIf="expandMainSidenav" class="menu__item-text">Change Logs</div>
    </a>

    <a class="menu__item" routerLink="/help-topics-user" routerLinkActive="menu__item--active">
        <mat-icon class="menu__item-img"
            [ngClass]="{ 'menu__item-img--shrank': !expandMainSidenav}">help_outlined</mat-icon>
        <div *ngIf="expandMainSidenav" class="menu__item-text">Help</div>
    </a>

    <div [ngClass]="{ 'justify-center': !expandMainSidenav, 'justify-between': expandMainSidenav }"
        class="flex items-center mt-auto">
        <a class="menu__item" routerLink="/user-profile" routerLinkActive="menu__item--active">
            <!--User profile svg-->
            <svg [ngClass]="{ 'menu__item-img': true, 'menu__item-img--shrank': !expandMainSidenav }" width="24"
                height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.5 0L19 5.5V16.5L9.5 22L0 16.5V5.5L9.5 0ZM9.5 2.311L2 6.653V15.347L9.5 19.689L17 15.347V6.653L9.5 2.311ZM9.5 15C8.43913 15 7.42172 14.5786 6.67157 13.8284C5.92143 13.0783 5.5 12.0609 5.5 11C5.5 9.93913 5.92143 8.92172 6.67157 8.17157C7.42172 7.42143 8.43913 7 9.5 7C10.5609 7 11.5783 7.42143 12.3284 8.17157C13.0786 8.92172 13.5 9.93913 13.5 11C13.5 12.0609 13.0786 13.0783 12.3284 13.8284C11.5783 14.5786 10.5609 15 9.5 15ZM9.5 13C10.0304 13 10.5391 12.7893 10.9142 12.4142C11.2893 12.0391 11.5 11.5304 11.5 11C11.5 10.4696 11.2893 9.96086 10.9142 9.58579C10.5391 9.21071 10.0304 9 9.5 9C8.96957 9 8.46086 9.21071 8.08579 9.58579C7.71071 9.96086 7.5 10.4696 7.5 11C7.5 11.5304 7.71071 12.0391 8.08579 12.4142C8.46086 12.7893 8.96957 13 9.5 13Z"
                    fill="#4B5563" />
            </svg>

            <div *ngIf="expandMainSidenav" class="menu__item-text">My Profile</div>
        </a>
    </div>
</nav>