export class Permissions {
    static readonly DashboardView = 'Permissions.Dashboard.View';
    static readonly DashboardEdit = 'Permissions.Dashboard.Edit';
    static readonly AdminPanelView = 'Permissions.AdminPanel.View';
    static readonly AdminPanelEdit = 'Permissions.AdminPanel.Edit';
    static readonly ApplicationFieldsView = 'Permissions.ApplicationFields.View';
    static readonly ApplicationFieldsEdit = 'Permissions.ApplicationFields.Edit';
    static readonly UsersView = 'Permissions.Users.View';
    static readonly UsersEdit = 'Permissions.Users.Edit';
    static readonly CloudNormalsView = 'Permissions.CloudNormals.View';
    static readonly CloudNormalsEdit = 'Permissions.CloudNormals.Edit';
    static readonly ConnectorsView = 'Permissions.Connectors.View';
    static readonly ConnectorsEdit = 'Permissions.Connectors.Edit';
    static readonly ConnectorTenantsView = 'Permissions.ConnectorTenants.View';
    static readonly ConnectorTenantsEdit = 'Permissions.ConnectorTenants.Edit';
    static readonly MasterConnectorView = 'Permissions.MasterConnector.View';
    static readonly MasterConnectorEdit = 'Permissions.MasterConnector.Edit';
    static readonly DataMapFieldsView = 'Permissions.DataMaps.View';
    static readonly DataMapFieldsEdit = 'Permissions.DataMaps.Edit';
    static readonly HelpTopicsView = 'Permissions.HelpTopics.View';
    static readonly HelpTopicsEdit = 'Permissions.HelpTopics.Edit';
    static readonly TriggersView = 'Permissions.Triggers.View';
    static readonly TriggersEdit = 'Permissions.Triggers.Edit';
    static readonly IntegrationsView = 'Permissions.Integrations.View';
    static readonly IntegrationsEdit = 'Permissions.Integrations.Edit';
    static readonly MarketDeliveredAndStatusView = 'Permissions.MarketDeliveredAndStatus.View';
    static readonly MarketDeliveredAndStatusEdit = 'Permissions.MarketDeliveredAndStatus.Edit';
    static readonly IFrameView = 'Permissions.IFrame.View';
    static readonly IFrameEdit = 'Permissions.IFrame.Edit';
    static readonly ReviewsAndQuestionsView = 'Permissions.ReviewsAndQuestions.View';
    static readonly ReviewsAndQuestionsEdit = 'Permissions.ReviewsAndQuestions.Edit';
    static readonly QuestionClientView = 'Permissions.QuestionsClient.View';
    static readonly QuestionClientEdit = 'Permissions.QuestionsClient.Edit';
    static readonly QuestionMasterView = 'Permissions.QuestionsMaster.View';
    static readonly QuestionMasterEdit = 'Permissions.QuestionsMaster.Edit';
    static readonly IntegrationScheduleView = 'Permissions.IntegrationSchedule.View';
    static readonly IntegrationScheduleEdit = 'Permissions.IntegrationSchedule.Edit';
    static readonly ConnectorUsersView = 'Permissions.ConnectorUsers.View';
    static readonly ConnectorUsersEdit = 'Permissions.ConnectorUsers.Edit';
    static readonly ConnectorsMaskingView = 'Permissions.ConnectorsMasking.View';
    static readonly ConnectorsMaskingEdit = 'Permissions.ConnectorsMasking.Edit';
    static readonly AIIntegrationView = 'Permissions.AIIntegration.View';
    static readonly AIIntegrationEdit = 'Permissions.AIIntegration.Edit';
    static readonly ProvisionTenantView = 'Permissions.ProvisionTenant.View';
    static readonly ProvisionTenantEdit = 'Permissions.ProvisionTenant.Edit';
    static readonly PartnerEngagementPagesView = 'Permissions.PartnerEngagementPages.View';
    static readonly PartnerEngagementPagesEdit = 'Permissions.PartnerEngagementPages.Edit';
    static readonly IntegrationQuickStartView = 'Permissions.IntegrationQuickStart.View';
    static readonly IntegrationQuickStartEdit = 'Permissions.IntegrationQuickStart.Edit';
    static readonly ChangeLogsView = 'Permissions.ChangeLogs.View';
    static readonly ChangeLogsEdit = 'Permissions.ChangeLogs.Edit';
    static readonly PartnerIntegrationClientView = 'Permissions.PartnerIntegrationClient.View';
    static readonly PartnerIntegrationClientEdit = 'Permissions.PartnerIntegrationClient.Edit';
    static readonly PartnerIntegrationUserView = 'Permissions.PartnerIntegrationUser.View';
    static readonly PartnerIntegrationUserEdit = 'Permissions.PartnerIntegrationUser.Edit';
    static readonly RPPMasterRecordView = 'Permissions.RPPMasterRecord.View';
    static readonly RPPMasterRecordEdit = 'Permissions.RPPMasterRecord.Edit';
    static readonly PortalEventNotificationsView = 'Permissions.PortalEventNotifications.View';
    static readonly PortalEventNotificationsEdit ='Permissions.PortalEventNotifications.Edit';
    static readonly ProfessionalServicesView = 'Permissions.ProfessionalServices.View';
    static readonly ProfessionalServicesEdit ='Permissions.ProfessionalServices.Edit';

    public static getPartnerPageIdFromPermission(permission: string): number {
        let pageId = permission.replace(`${Permissions.PartnerEngagementPagesView}.`, "");
        return parseInt(pageId, 10);
    }
}
