import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { TeamMembersActions } from '../action-types/action-types';
import { TeamMember } from '../../models/team-member/team-member.model';
import {
    selectIntegrationManagers,
    selectLoaded,
    selectLoading,
    selectLoadingById,
    selectMechanics,
    selectPartners,
    selectRppIMs,
    selectRppMechanics,
    selectSalespersons,
    selectTeamMemberById,
    selectTeamMembers
} from './team-members.selectors';

@Injectable({
    providedIn: 'root'
})
export class TeamMembersFacade {
    public teamMembers$: Observable<Array<TeamMember>>;
    public integrationManagers$: Observable<Array<TeamMember>>;
    public mechanics$: Observable<Array<TeamMember>>;
    public partners$: Observable<Array<TeamMember>>;
    public salespersons$: Observable<Array<TeamMember>>;
    public loading$: Observable<boolean>;
    public loadingById$: Observable<boolean>;
    public loaded$: Observable<boolean>;
    public rppIMs$: Observable<Array<TeamMember>>;
    public rppMechanics$: Observable<Array<TeamMember>>;
    public selectedTeamMember$: Observable<TeamMember>;
    public selectedTeamMemberChanged$ = new Subject<void>();

    constructor(private store: Store<AppState>) {
        this.teamMembers$ = store.select(selectTeamMembers);
        this.integrationManagers$ = store.select(selectIntegrationManagers);
        this.mechanics$ = store.select(selectMechanics);
        this.partners$ = store.select(selectPartners);
        this.salespersons$ = store.select(selectSalespersons);
        this.loading$ = this.store.select(selectLoading);
        this.loadingById$ = this.store.select(selectLoadingById);
        this.loaded$ = this.store.select(selectLoaded);
        this.rppIMs$ = this.store.select(selectRppIMs);
        this.rppMechanics$ = this.store.select(selectRppMechanics);
        this.store.dispatch(TeamMembersActions.loadTeamMembers());
    }

    public selectTeamMemberById(id: number): void {
        if (!id) {
            this.selectedTeamMember$ = of({} as TeamMember);
            this.selectedTeamMemberChanged$.next();
            return;
        }

        this.selectedTeamMember$ = this.store.select(selectTeamMemberById(id));
        this.store.dispatch(TeamMembersActions.loadTeamMemberById({ id }));
        this.selectedTeamMemberChanged$.next();
    }

    public updateTeamMember(teamMember: TeamMember): void {
        this.store.dispatch(TeamMembersActions.updateTeamMember({ teamMember }));
    }

    public createTeamMember(teamMember: TeamMember): void {
        this.store.dispatch(TeamMembersActions.createTeamMember({ teamMember }));
    }

    public deleteTeamMember(id: number): void {
        this.store.dispatch(TeamMembersActions.deleteTeamMember({ id }));
    }
}
